import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';
import './LoadsPage.css';

const LoadDetailsPage = ({ load, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Load Details (ID: {load.id})</DialogTitle>
      <DialogContent>
        <Typography><strong>Chat ID:</strong> {load.chat_id.Int64}</Typography>
        <Typography><strong>Truck ID:</strong> {load.truck.truck_id}</Typography>
        <Typography><strong>Trailer ID:</strong> {load.trailer_id.Int64}</Typography>
        <Typography><strong>User ID:</strong> {load.user_id.String}</Typography>
        <Typography><strong>Driver:</strong> {load.driver.name || `${load.driver.first_name} ${load.driver.last_name}`}</Typography>
        <Typography><strong>Chat Name:</strong> {load.chat_name.String}</Typography>
        <Typography><strong>Load ID:</strong> {load.load_id.String}</Typography>
        <Typography><strong>Gross:</strong> ${load.gross.Float64.toFixed(2)}</Typography>
        <Typography><strong>Distance:</strong> {load.distance.Float64} miles</Typography>
        <Typography><strong>Created At:</strong> {new Date(load.created_at).toLocaleString()}</Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>Locations:</Typography>
        <List>
          {load.locations && load.locations.map(loc => (
            <ListItem key={loc.id}>
              <ListItemText
                primary={`${loc.type === 0 ? 'Pickup' : 'Delivery'}: ${loc.location.address.String}`}
                secondary={`Name: ${loc.location.name.String} | Phone: ${loc.contact_phone.String} | Appointment: ${loc.appointment.String}`}
              />
            </ListItem>
          ))}
        </List>

        <Typography variant="h6" sx={{ mt: 2 }}>Documents:</Typography>
        <List>
          {load.documents && load.documents.map(doc => (
            <ListItem key={doc.id}>
              <ListItemText primary={doc.info.String} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadDetailsPage;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadDetailsPage from './LoadDetailsPage';
import { Card, CardContent, Typography, Container, Box } from '@mui/material';
import './LoadsPage.css';

const LoadsPage = () => {
  const [loads, setLoads] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLoads = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await axios.get('/api/get_loads', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLoads(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch loads: ' + (err.response?.data?.message || err.message));
        setLoading(false);
      }
    };
    fetchLoads();
  }, []);

  const groupAndSortByTruck = (loads) => {
    const grouped = {};
    loads.forEach(load => {
      const truckId = load.truck?.truck_id || 'Unknown';
      if (!grouped[truckId]) grouped[truckId] = [];
      grouped[truckId].push(load);
    });

    Object.keys(grouped).forEach(truckId => {
      grouped[truckId].sort((a, b) => {
        const dateA = new Date(a.created_at || 0);
        const dateB = new Date(b.created_at || 0);
        return dateB - dateA; // Oldest to earliest
      });
    });

    return grouped;
  };

  const getFirstPickup = (locations) => {
    if (!Array.isArray(locations) || locations.length === 0) return 'N/A';
    const pickups = locations.filter(loc => loc.type === 0);
    if (pickups.length === 0) return 'N/A';
    return pickups.reduce((min, loc) =>
      min.location_order < loc.location_order ? min : loc, pickups[0])?.location?.address.String || 'N/A';
  };

  const getLastDelivery = (locations) => {
    if (!Array.isArray(locations) || locations.length === 0) return 'N/A';
    const deliveries = locations.filter(loc => loc.type === 1);
    if (deliveries.length === 0) return 'N/A';
    return deliveries.reduce((max, loc) =>
      max.location_order > loc.location_order ? max : loc, deliveries[0])?.location?.address.String || 'N/A';
  };

  const handleCardClick = (load) => {
    setSelectedLoad(load);
  };

  const closeModal = () => {
    setSelectedLoad(null);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const groupedLoads = groupAndSortByTruck(loads);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Loads by Truck</Typography>
      <Box sx={{
        overflowX: 'auto',
        position: 'relative',
        pb: 1,
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.2)',
          borderRadius: '4px',
        }
      }}>
        {Object.entries(groupedLoads).map(([truckId, truckLoads]) => (
          <Box
            key={truckId}
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              mb: 2,
              minWidth: 'fit-content' // Ensures the row doesn't shrink
            }}
          >
            {/* Fixed Truck ID Column */}
            <Box
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: 'white', // Prevents cards from showing through
                zIndex: 1,
                minWidth: '150px',
                display: 'flex',
                alignItems: 'center',
                px: 2,
                borderRight: '1px solid #ddd'
              }}
            >
              <Typography variant="h6">
                {truckId}
              </Typography>
            </Box>

            {/* Scrollable Cards */}
            <Box sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: 2,
              alignItems: 'stretch'
            }}>
              {truckLoads.map(load => (
                <Card
                  key={load.id}
                  onClick={() => handleCardClick(load)}
                  sx={{
                    flex: '0 0 auto',
                    minWidth: '200px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography><strong>Load ID:</strong> {load.load_id.String}</Typography>
                    <Typography><strong>First Pickup:</strong> {getFirstPickup(load.locations)}</Typography>
                    <Typography><strong>Last Delivery:</strong> {getLastDelivery(load.locations)}</Typography>
                    <Typography><strong>Gross:</strong> ${load.gross.Float64.toFixed(2)}</Typography>
                    <Typography><strong>Miles:</strong> {load.distance.Float64}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      {selectedLoad && <LoadDetailsPage load={selectedLoad} onClose={closeModal} />}
    </Container>
  );
};

export default LoadsPage;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Slider, TextField, Typography, Modal, Box, Button, Checkbox, FormControlLabel, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './StationaryTrucksPage.css';

// Haversine formula to calculate distance in miles
const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth's radius in miles
  const toRad = (deg) => deg * Math.PI / 180;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) ** 2 +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const StationaryTrucksPage = () => {
  const [trucks, setTrucks] = useState([]);
  const [timeRange, setTimeRange] = useState(24);
  const [radius, setRadius] = useState(50);
  const [showOutOfZone, setShowOutOfZone] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [history, setHistory] = useState([]);
  const [currentTime, setCurrentTime] = useState(null); // Store server current time
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/truck_locations', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!Array.isArray(response.data)) {
          throw new Error('Invalid response: Expected an array of trucks');
        }

        const processedTrucks = response.data
          .map(truck => {
            if (!truck || !Array.isArray(truck.locations) || truck.locations.length === 0) {
              console.warn('Invalid truck data:', truck);
              return null;
            }

            const locations = truck.locations;
            const latest = locations[0];
            const first = locations[locations.length - 1];
            const compareTime = new Date(Date.now() - timeRange * 60 * 60 * 1000);
            const compareLoc = locations.find(loc => new Date(loc.created_at) <= compareTime) || first;

            if (!latest.latitude || !latest.longitude || !compareLoc.latitude || !compareLoc.longitude || !first.latitude || !first.longitude) {
              return null;
            }

            const distanceToCompare = haversineDistance(
              latest.latitude, latest.longitude,
              compareLoc.latitude, compareLoc.longitude
            );
            const distanceToFirst = haversineDistance(
              latest.latitude, latest.longitude,
              first.latitude, first.longitude
            );

            return {
              ...truck,
              distanceToFirst,
              isWithinRadius: distanceToCompare <= radius,
            };
          })
          .filter(truck => truck !== null)
          .sort((a, b) => (a.distanceToFirst || 0) - (b.distanceToFirst || 0));

        const filteredTrucks = processedTrucks.filter(truck =>
          showOutOfZone ? true : truck.isWithinRadius
        );

        setTrucks(filteredTrucks);
        setLoading(false);
      } catch (err) {
        console.error('Fetch error:', err);
        setError('Failed to fetch trucks: ' + (err.response?.data?.message || err.message));
        setLoading(false);
      }
    };
    fetchTrucks();
  }, [timeRange, radius, showOutOfZone]);

  const fetchTruckHistory = async (truckID) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/truck_locations/${truckID}?range=week`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.data || !Array.isArray(response.data.locations) || !response.data.current_time) {
        throw new Error('Invalid history response: ' + JSON.stringify(response.data));
      }
      setHistory(response.data.locations);
      setCurrentTime(response.data.current_time); // Set server current time
      setSelectedTruck(truckID);
    } catch (err) {
      console.error('Fetch truck history error:', err.response || err);
      setError('Failed to fetch truck history: ' + (err.response?.data?.message || err.message));
    }
  };

  const handleCloseModal = () => {
    setSelectedTruck(null);
    setHistory([]);
    setCurrentTime(null);
  };

  // Process history to collapse by mileage and calculate time at location
  const processHistory = (history, serverCurrentTime) => {
    if (history.length === 0 || !serverCurrentTime) return [];

    const processed = [];
    let currentGroup = { ...history[0], startTime: new Date(history[0].created_at) };

    for (let i = 1; i < history.length; i++) {
      const current = history[i];
      const prev = history[i - 1];

      if (current.mileage === prev.mileage && (current.location_name || 'N/A') === (prev.location_name || 'N/A')) {
        // Same mileage and location, update start to earliest time
        currentGroup.startTime = new Date(current.created_at);
      } else {
        // Mileage or location changed, finalize previous group
        const earliestNextTime = new Date(current.created_at); // Earliest time of the next group
        const timeDiff = (earliestNextTime - currentGroup.startTime) / (1000 * 60 * 60); // Hours
        processed.push({ ...currentGroup, timeAtLocation: timeDiff.toFixed(2) });
        currentGroup = { ...current, startTime: new Date(current.created_at) };
      }
    }

    // Finalize the last group using server current time
    const serverTime = new Date(serverCurrentTime);
    const timeDiff = (serverTime - currentGroup.startTime) / (1000 * 60 * 60); // Hours
    processed.push({ ...currentGroup, timeAtLocation: timeDiff.toFixed(2) });

    return processed;
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  const processedHistory = processHistory(history, currentTime);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Stationary Trucks</Typography>

      <Box sx={{ mb: 4 }}>
        <Typography>Time Range (hours): {timeRange}</Typography>
        <Slider
          value={timeRange}
          onChange={(e, val) => setTimeRange(val)}
          min={0}
          max={24}
          step={1}
          valueLabelDisplay="auto"
        />
        <TextField
          type="number"
          value={timeRange}
          onChange={(e) => setTimeRange(Math.min(24, Math.max(0, Number(e.target.value))))}
          label="Hours"
          variant="outlined"
          sx={{ width: 100 }}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography>Radius (miles): {radius}</Typography>
        <Slider
          value={radius}
          onChange={(e, val) => setRadius(val)}
          min={0}
          max={50}
          step={1}
          valueLabelDisplay="auto"
        />
        <TextField
          type="number"
          value={radius}
          onChange={(e) => setRadius(Math.min(50, Math.max(0, Number(e.target.value))))}
          label="Miles"
          variant="outlined"
          sx={{ width: 100 }}
        />
      </Box>

      <FormControlLabel
        control={<Checkbox checked={showOutOfZone} onChange={(e) => setShowOutOfZone(e.target.checked)} />}
        label="Show trucks outside radius"
        sx={{ mb: 2 }}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Truck ID</TableCell>
              <TableCell>Current Mileage (miles)</TableCell>
              <TableCell>Current Location</TableCell>
              <TableCell>Distance from First Location (miles)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trucks.map(truck => (
              <TableRow key={truck.id} onClick={() => fetchTruckHistory(truck.truck_id)} className="truck-row">
                <TableCell>{truck.truck_id}</TableCell>
                <TableCell>{(truck.locations[0]?.mileage / 1609.34).toFixed(2) || 'N/A'}</TableCell>
                <TableCell>{truck.locations[0]?.location_name || 'N/A'}</TableCell>
                <TableCell>{truck.distanceToFirst.toFixed(2) || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={!!selectedTruck} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          width: 700,
          maxHeight: '80vh',
          borderRadius: 2,
          overflow: 'hidden'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            borderBottom: '1px solid #e0e0e0',
            pb: 1
          }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Location History Report for Truck {selectedTruck}
            </Typography>
            <IconButton onClick={handleCloseModal} sx={{ color: '#f44336' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          {processedHistory.length > 0 ? (
            <TableContainer sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Distance from Current (miles)</TableCell>
                    <TableCell>Mileage (miles)</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Time at Location (hours)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processedHistory.map(loc => {
                    const latest = history[0];
                    const distance = latest.latitude && loc.latitude
                      ? haversineDistance(latest.latitude, latest.longitude, loc.latitude, loc.longitude).toFixed(2)
                      : 'N/A';
                    return (
                      <TableRow key={loc.id}>
                        <TableCell>{new Date(loc.created_at).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                        <TableCell>{distance}</TableCell>
                        <TableCell>{(loc.mileage / 1609.34).toFixed(2)}</TableCell>
                        <TableCell>{loc.location_name || 'N/A'}</TableCell>
                        <TableCell>{loc.timeAtLocation}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No history available for the last week.</Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default StationaryTrucksPage;
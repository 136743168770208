import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import LoadsPage from './loads/LoadsPage';
import DriverHiring from './drivers/DriverHiring';
import TruckList from './trucks/TruckList';
import TruckDetails from './trucks/TruckDetails';
import UsersTable from './users/UsersTable';
import Login from './Login';
import Register from './Register';
import PrivacyPolicy from './PrivacyPolicy';
import Team from './Team';
import About from './About';
import StationaryTrucksPage from './trucks/StationaryTrucksPage';

const isAuthenticated = () => !!localStorage.getItem('token');

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<AppLayout />}>
          {/* Public Routes */}
          <Route index element={<About />} />
          <Route path="drivers" element={<DriverHiring />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="team" element={<Team />} />
          {/* Protected Routes */}
          <Route path="loads" element={<ProtectedRoute><LoadsPage /></ProtectedRoute>} />
          <Route path="trucks" element={<ProtectedRoute><TruckList /></ProtectedRoute>} />
          <Route path="truck/:id" element={<ProtectedRoute><TruckDetails /></ProtectedRoute>} />
          <Route path="users" element={<ProtectedRoute><UsersTable /></ProtectedRoute>} />
          <Route path="stationary" element={<ProtectedRoute><StationaryTrucksPage /></ProtectedRoute>} /> {/* New Route */}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
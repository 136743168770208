import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const About = () => {
  return (
    <Container>
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>About PrimeTurbo</Typography>
        <Typography variant="body1" paragraph>
          PrimeTurbo is a leading trucking company dedicated to delivering goods efficiently and reliably across the nation.
          With a fleet of modern trucks and a team of experienced drivers, we ensure that your cargo reaches its destination on time, every time.
        </Typography>
        <Typography variant="body1" paragraph>
          In addition to our transportation services, PrimeTurbo operates a state-of-the-art repair shop specializing in trucks and trailers.
          Our skilled technicians provide top-notch maintenance and repair services to keep your vehicles in peak condition, minimizing downtime and maximizing performance.
        </Typography>
        <Typography variant="body1">
          At PrimeTurbo, we pride ourselves on our commitment to quality, safety, and customer satisfaction. Whether you're shipping freight or need vehicle repairs,
          PrimeTurbo is your trusted partner on the road.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
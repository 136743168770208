import React, { useState } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Container, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GroupIcon from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import './AppLayout.css';

const AppLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token')); // Store token in state

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (!isPinned) setDrawerOpen(false);
  };

  const handleLogin = () => {
    navigate('/login');
    if (!isPinned) setDrawerOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear from localStorage
    setToken(null); // Clear from memory (state)
    navigate('/login');
    if (!isPinned) setDrawerOpen(false);
  };

  const togglePin = () => {
    setIsPinned(!isPinned);
  };

  const isLoggedIn = !!token;

  const menuItems = [
    { text: 'About', path: '/', icon: <HomeIcon /> },
    ...(isLoggedIn ? [{ text: 'Loads', path: '/loads', icon: <LocalShippingIcon /> }] : []),
    { text: 'Drivers', path: '/drivers', icon: <PeopleIcon /> },
    ...(isLoggedIn ? [
      { text: 'Trucks', path: '/trucks', icon: <BuildIcon /> },
      { text: 'Stationary Trucks', path: '/stationary', icon: <LocalShippingIcon /> }, // New Item
      { text: 'Users', path: '/users', icon: <GroupIcon /> }
    ] : []),
    { text: 'Privacy Policy', path: '/privacy', icon: <PrivacyTipIcon /> },
    { text: 'Our Team', path: '/team', icon: <GroupIcon /> },
  ];

  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static" sx={{ backgroundColor: '#1a237e' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            PrimeTurbo
          </Typography>
          <IconButton edge="end" color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>

      <Box component="footer" sx={{ py: 2, backgroundColor: '#f5f5f5', textAlign: 'center' }}>
        <Container>
          <Typography variant="body2" color="textSecondary">
            © {currentYear} PrimeTurbo. All rights reserved. |{' '}
            <Link to="/privacy" style={{ color: '#1a237e', textDecoration: 'none' }}>
              Privacy Policy
            </Link>
          </Typography>
        </Container>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        variant={isPinned ? 'persistent' : 'temporary'}
        sx={{
          '& .MuiDrawer-paper': { width: 250, backgroundColor: '#fafafa' },
        }}
      >
        <Box sx={{ p: 2 }}>
          <List>
            {menuItems.map((item) => (
              <ListItem button key={item.text} onClick={() => handleNavigation(item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
            <Divider />
            <ListItem button onClick={togglePin}>
              <ListItemIcon>{isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}</ListItemIcon>
              <ListItemText primary={isPinned ? 'Unpin Drawer' : 'Pin Drawer'} />
            </ListItem>
            {isLoggedIn ? (
              <ListItem button onClick={handleLogout}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            ) : (
              <ListItem button onClick={handleLogin}>
                <ListItemIcon><LoginIcon /></ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AppLayout;
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Typography,
  Alert,
} from "@mui/material";

// Map roles to their names
const roleNames = [
  "None",
  "Iam",
  "Driver",
  "Dispatch",
  "Manager",
  "Owner",
];

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token"); // Get token from localStorage

    // Fetch users from the backend
    fetch("/api/users", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setUnauthorized(true); // Set unauthorized state if 401 is returned
          setLoading(false);
          return [];
        }
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      })
      .then((data) => {
        setUnauthorized(false); // Reset unauthorized if successful
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  }, []);

  const handleRoleChange = (userId, newRole) => {
    const token = localStorage.getItem("token"); // Get token from localStorage

    // Update role in the backend
    fetch("/api/users/update-role", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: userId, role: newRole }),
    })
      .then((response) => {
        if (response.status === 401) {
          setUnauthorized(true); // Set unauthorized state if 401 is returned
          return;
        }
        if (!response.ok) {
          throw new Error("Failed to update role");
        }
        // Update the role in the local state
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, role: newRole } : user
          )
        );
      })
      .catch((error) => console.error("Error updating role:", error));
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (unauthorized) {
    return (
      <Alert severity="error">
        Unauthorized access. Please log in or check your permissions.
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>
                <Select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user.id, e.target.value)}
                >
                  {roleNames.map((role, index) => (
                    <MenuItem key={index} value={index}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
